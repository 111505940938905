/*!
FullCalendar Core Package v4.0.2
Docs & License: https://fullcalendar.io/
(c) 2019 Adam Shaw
*/
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
      (global = global || self, global.FullCalendarLocalesAll = factory());
}(this, function () { 'use strict';

  var _m0 = {
    code: "af",
    week: {
      dow: 1,
      doy: 4 // Die week wat die 4de Januarie bevat is die eerste week van die jaar.
    },
    buttonText: {
      prev: "Vorige",
      next: "Volgende",
      today: "Vandag",
      year: "Jaar",
      month: "Maand",
      week: "Week",
      day: "Dag",
      list: "Agenda"
    },
    allDayHtml: "Heeldag",
    eventLimitText: "Addisionele",
    noEventsMessage: "Daar is geen gebeurtenisse nie"
  };

  var _m1 = {
    code: "ar-dz",
    week: {
      dow: 0,
      doy: 4 // The week that contains Jan 1st is the first week of the year.
    },
    dir: 'rtl',
    buttonText: {
      prev: "Ø§Ù„Ø³Ø§Ø¨Ù‚",
      next: "Ø§Ù„ØªØ§Ù„ÙŠ",
      today: "Ø§Ù„ÙŠÙˆÙ…",
      month: "Ø´Ù‡Ø±",
      week: "Ø£Ø³Ø¨ÙˆØ¹",
      day: "ÙŠÙˆÙ…",
      list: "Ø£Ø¬Ù†Ø¯Ø©"
    },
    weekLabel: "Ø£Ø³Ø¨ÙˆØ¹",
    allDayText: "Ø§Ù„ÙŠÙˆÙ… ÙƒÙ„Ù‡",
    eventLimitText: "Ø£Ø®Ø±Ù‰",
    noEventsMessage: "Ø£ÙŠ Ø£Ø­Ø¯Ø§Ø« Ù„Ø¹Ø±Ø¶"
  };

  var _m2 = {
    code: "ar-kw",
    week: {
      dow: 0,
      doy: 12 // The week that contains Jan 1st is the first week of the year.
    },
    dir: 'rtl',
    buttonText: {
      prev: "Ø§Ù„Ø³Ø§Ø¨Ù‚",
      next: "Ø§Ù„ØªØ§Ù„ÙŠ",
      today: "Ø§Ù„ÙŠÙˆÙ…",
      month: "Ø´Ù‡Ø±",
      week: "Ø£Ø³Ø¨ÙˆØ¹",
      day: "ÙŠÙˆÙ…",
      list: "Ø£Ø¬Ù†Ø¯Ø©"
    },
    weekLabel: "Ø£Ø³Ø¨ÙˆØ¹",
    allDayText: "Ø§Ù„ÙŠÙˆÙ… ÙƒÙ„Ù‡",
    eventLimitText: "Ø£Ø®Ø±Ù‰",
    noEventsMessage: "Ø£ÙŠ Ø£Ø­Ø¯Ø§Ø« Ù„Ø¹Ø±Ø¶"
  };

  var _m3 = {
    code: "ar-ly",
    week: {
      dow: 6,
      doy: 12 // The week that contains Jan 1st is the first week of the year.
    },
    dir: 'rtl',
    buttonText: {
      prev: "Ø§Ù„Ø³Ø§Ø¨Ù‚",
      next: "Ø§Ù„ØªØ§Ù„ÙŠ",
      today: "Ø§Ù„ÙŠÙˆÙ…",
      month: "Ø´Ù‡Ø±",
      week: "Ø£Ø³Ø¨ÙˆØ¹",
      day: "ÙŠÙˆÙ…",
      list: "Ø£Ø¬Ù†Ø¯Ø©"
    },
    weekLabel: "Ø£Ø³Ø¨ÙˆØ¹",
    allDayText: "Ø§Ù„ÙŠÙˆÙ… ÙƒÙ„Ù‡",
    eventLimitText: "Ø£Ø®Ø±Ù‰",
    noEventsMessage: "Ø£ÙŠ Ø£Ø­Ø¯Ø§Ø« Ù„Ø¹Ø±Ø¶"
  };

  var _m4 = {
    code: "ar-ma",
    week: {
      dow: 6,
      doy: 12 // The week that contains Jan 1st is the first week of the year.
    },
    dir: 'rtl',
    buttonText: {
      prev: "Ø§Ù„Ø³Ø§Ø¨Ù‚",
      next: "Ø§Ù„ØªØ§Ù„ÙŠ",
      today: "Ø§Ù„ÙŠÙˆÙ…",
      month: "Ø´Ù‡Ø±",
      week: "Ø£Ø³Ø¨ÙˆØ¹",
      day: "ÙŠÙˆÙ…",
      list: "Ø£Ø¬Ù†Ø¯Ø©"
    },
    weekLabel: "Ø£Ø³Ø¨ÙˆØ¹",
    allDayText: "Ø§Ù„ÙŠÙˆÙ… ÙƒÙ„Ù‡",
    eventLimitText: "Ø£Ø®Ø±Ù‰",
    noEventsMessage: "Ø£ÙŠ Ø£Ø­Ø¯Ø§Ø« Ù„Ø¹Ø±Ø¶"
  };

  var _m5 = {
    code: "ar-sa",
    week: {
      dow: 0,
      doy: 6 // The week that contains Jan 1st is the first week of the year.
    },
    dir: 'rtl',
    buttonText: {
      prev: "Ø§Ù„Ø³Ø§Ø¨Ù‚",
      next: "Ø§Ù„ØªØ§Ù„ÙŠ",
      today: "Ø§Ù„ÙŠÙˆÙ…",
      month: "Ø´Ù‡Ø±",
      week: "Ø£Ø³Ø¨ÙˆØ¹",
      day: "ÙŠÙˆÙ…",
      list: "Ø£Ø¬Ù†Ø¯Ø©"
    },
    weekLabel: "Ø£Ø³Ø¨ÙˆØ¹",
    allDayText: "Ø§Ù„ÙŠÙˆÙ… ÙƒÙ„Ù‡",
    eventLimitText: "Ø£Ø®Ø±Ù‰",
    noEventsMessage: "Ø£ÙŠ Ø£Ø­Ø¯Ø§Ø« Ù„Ø¹Ø±Ø¶"
  };

  var _m6 = {
    code: "ar-tn",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    dir: 'rtl',
    buttonText: {
      prev: "Ø§Ù„Ø³Ø§Ø¨Ù‚",
      next: "Ø§Ù„ØªØ§Ù„ÙŠ",
      today: "Ø§Ù„ÙŠÙˆÙ…",
      month: "Ø´Ù‡Ø±",
      week: "Ø£Ø³Ø¨ÙˆØ¹",
      day: "ÙŠÙˆÙ…",
      list: "Ø£Ø¬Ù†Ø¯Ø©"
    },
    weekLabel: "Ø£Ø³Ø¨ÙˆØ¹",
    allDayText: "Ø§Ù„ÙŠÙˆÙ… ÙƒÙ„Ù‡",
    eventLimitText: "Ø£Ø®Ø±Ù‰",
    noEventsMessage: "Ø£ÙŠ Ø£Ø­Ø¯Ø§Ø« Ù„Ø¹Ø±Ø¶"
  };

  var _m7 = {
    code: "ar",
    week: {
      dow: 6,
      doy: 12 // The week that contains Jan 1st is the first week of the year.
    },
    dir: 'rtl',
    buttonText: {
      prev: "Ø§Ù„Ø³Ø§Ø¨Ù‚",
      next: "Ø§Ù„ØªØ§Ù„ÙŠ",
      today: "Ø§Ù„ÙŠÙˆÙ…",
      month: "Ø´Ù‡Ø±",
      week: "Ø£Ø³Ø¨ÙˆØ¹",
      day: "ÙŠÙˆÙ…",
      list: "Ø£Ø¬Ù†Ø¯Ø©"
    },
    weekLabel: "Ø£Ø³Ø¨ÙˆØ¹",
    allDayText: "Ø§Ù„ÙŠÙˆÙ… ÙƒÙ„Ù‡",
    eventLimitText: "Ø£Ø®Ø±Ù‰",
    noEventsMessage: "Ø£ÙŠ Ø£Ø­Ø¯Ø§Ø« Ù„Ø¹Ø±Ø¶"
  };

  var _m8 = {
    code: "bg",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "Ð½Ð°Ð·Ð°Ð´",
      next: "Ð½Ð°Ð¿Ñ€ÐµÐ´",
      today: "Ð´Ð½ÐµÑ",
      month: "ÐœÐµÑÐµÑ†",
      week: "Ð¡ÐµÐ´Ð¼Ð¸Ñ†Ð°",
      day: "Ð”ÐµÐ½",
      list: "Ð“Ñ€Ð°Ñ„Ð¸Ðº"
    },
    allDayText: "Ð¦ÑÐ» Ð´ÐµÐ½",
    eventLimitText: function (n) {
      return "+Ð¾Ñ‰Ðµ " + n;
    },
    noEventsMessage: "ÐÑÐ¼Ð° ÑÑŠÐ±Ð¸Ñ‚Ð¸Ñ Ð·Ð° Ð¿Ð¾ÐºÐ°Ð·Ð²Ð°Ð½Ðµ"
  };

  var _m9 = {
    code: "bs",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "ProÅ¡li",
      next: "SljedeÄ‡i",
      today: "Danas",
      month: "Mjesec",
      week: "Sedmica",
      day: "Dan",
      list: "Raspored"
    },
    weekLabel: "Sed",
    allDayText: "Cijeli dan",
    eventLimitText: function (n) {
      return "+ joÅ¡ " + n;
    },
    noEventsMessage: "Nema dogaÄ‘aja za prikazivanje"
  };

  var _m10 = {
    code: "ca",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Anterior",
      next: "SegÃ¼ent",
      today: "Avui",
      month: "Mes",
      week: "Setmana",
      day: "Dia",
      list: "Agenda"
    },
    weekLabel: "Set",
    allDayText: "Tot el dia",
    eventLimitText: "mÃ©s",
    noEventsMessage: "No hi ha esdeveniments per mostrar"
  };

  var _m11 = {
    code: "cs",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "DÅ™Ã­ve",
      next: "PozdÄ›ji",
      today: "NynÃ­",
      month: "MÄ›sÃ­c",
      week: "TÃ½den",
      day: "Den",
      list: "Agenda"
    },
    weekLabel: "TÃ½d",
    allDayText: "CelÃ½ den",
    eventLimitText: function (n) {
      return "+dalÅ¡Ã­: " + n;
    },
    noEventsMessage: "Å½Ã¡dnÃ© akce k zobrazenÃ­"
  };

  var _m12 = {
    code: "da",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Forrige",
      next: "NÃ¦ste",
      today: "Idag",
      month: "MÃ¥ned",
      week: "Uge",
      day: "Dag",
      list: "Agenda"
    },
    weekLabel: "Uge",
    allDayText: "Hele dagen",
    eventLimitText: "flere",
    noEventsMessage: "Ingen arrangementer at vise"
  };

  var _m13 = {
    code: "de",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Zurück",
      next: "Vor",
      today: "Heute",
      year: "Jahr",
      month: "Monat",
      week: "Woche",
      day: "Tag",
      list: "Terminübersicht"
    },
    weekLabel: "KW",
    allDayText: "Ganztägig",
    eventLimitText: function (n) {
      return "+ weitere " + n;
    },
    noEventsMessage: "Keine Ereignisse anzuzeigen"
  };

  var _m14 = {
    code: "el",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4st is the first week of the year.
    },
    buttonText: {
      prev: "Î ÏÎ¿Î·Î³Î¿ÏÎ¼ÎµÎ½Î¿Ï‚",
      next: "Î•Ï€ÏŒÎ¼ÎµÎ½Î¿Ï‚",
      today: "Î£Î®Î¼ÎµÏÎ±",
      month: "ÎœÎ®Î½Î±Ï‚",
      week: "Î•Î²Î´Î¿Î¼Î¬Î´Î±",
      day: "Î—Î¼Î­ÏÎ±",
      list: "Î‘Ï„Î¶Î­Î½Ï„Î±"
    },
    weekLabel: "Î•Î²Î´",
    allDayText: "ÎŸÎ»Î¿Î®Î¼ÎµÏÎ¿",
    eventLimitText: "Ï€ÎµÏÎ¹ÏƒÏƒÏŒÏ„ÎµÏÎ±",
    noEventsMessage: "Î”ÎµÎ½ Ï…Ï€Î¬ÏÏ‡Î¿Ï…Î½ Î³ÎµÎ³Î¿Î½ÏŒÏ„Î± Î³Î¹Î± Î½Î± ÎµÎ¼Ï†Î±Î½Î¹ÏƒÏ„ÎµÎ¯"
  };

  var _m15 = {
    code: "en-au",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
  };

  var _m16 = {
    code: "en-gb",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
  };

  var _m17 = {
    code: "en-nz",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
  };

  var _m18 = {
    code: "es",
    week: {
      dow: 0,
      doy: 6 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "Ant",
      next: "Sig",
      today: "Hoy",
      month: "Mes",
      week: "Semana",
      day: "DÃ­a",
      list: "Agenda"
    },
    weekLabel: "Sm",
    allDayHtml: "Todo<br/>el dÃ­a",
    eventLimitText: "mÃ¡s",
    noEventsMessage: "No hay eventos para mostrar"
  };

  var _m19 = {
    code: "es",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Ant",
      next: "Sig",
      today: "Hoy",
      month: "Mes",
      week: "Semana",
      day: "DÃ­a",
      list: "Agenda"
    },
    weekLabel: "Sm",
    allDayHtml: "Todo<br/>el dÃ­a",
    eventLimitText: "mÃ¡s",
    noEventsMessage: "No hay eventos para mostrar"
  };

  var _m20 = {
    code: "et",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Eelnev",
      next: "JÃ¤rgnev",
      today: "TÃ¤na",
      month: "Kuu",
      week: "NÃ¤dal",
      day: "PÃ¤ev",
      list: "PÃ¤evakord"
    },
    weekLabel: "nÃ¤d",
    allDayText: "Kogu pÃ¤ev",
    eventLimitText: function (n) {
      return "+ veel " + n;
    },
    noEventsMessage: "Kuvamiseks puuduvad sÃ¼ndmused"
  };

  var _m21 = {
    code: "eu",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "Aur",
      next: "Hur",
      today: "Gaur",
      month: "Hilabetea",
      week: "Astea",
      day: "Eguna",
      list: "Agenda"
    },
    weekLabel: "As",
    allDayHtml: "Egun<br/>osoa",
    eventLimitText: "gehiago",
    noEventsMessage: "Ez dago ekitaldirik erakusteko"
  };

  var _m22 = {
    code: "fa",
    week: {
      dow: 6,
      doy: 12 // The week that contains Jan 1st is the first week of the year.
    },
    dir: 'rtl',
    buttonText: {
      prev: "Ù‚Ø¨Ù„ÛŒ",
      next: "Ø¨Ø¹Ø¯ÛŒ",
      today: "Ø§Ù…Ø±ÙˆØ²",
      month: "Ù…Ø§Ù‡",
      week: "Ù‡ÙØªÙ‡",
      day: "Ø±ÙˆØ²",
      list: "Ø¨Ø±Ù†Ø§Ù…Ù‡"
    },
    weekLabel: "Ù‡Ù",
    allDayText: "ØªÙ…Ø§Ù… Ø±ÙˆØ²",
    eventLimitText: function (n) {
      return "Ø¨ÛŒØ´ Ø§Ø² " + n;
    },
    noEventsMessage: "Ù‡ÛŒÚ† Ø±ÙˆÛŒØ¯Ø§Ø¯ÛŒ Ø¨Ù‡ Ù†Ù…Ø§ÛŒØ´"
  };

  var _m23 = {
    code: "fi",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Edellinen",
      next: "Seuraava",
      today: "TÃ¤nÃ¤Ã¤n",
      month: "Kuukausi",
      week: "Viikko",
      day: "PÃ¤ivÃ¤",
      list: "Tapahtumat"
    },
    weekLabel: "Vk",
    allDayText: "Koko pÃ¤ivÃ¤",
    eventLimitText: "lisÃ¤Ã¤",
    noEventsMessage: "Ei nÃ¤ytettÃ¤viÃ¤ tapahtumia"
  };

  var _m24 = {
    code: "fr",
    buttonText: {
      prev: "PrÃ©cÃ©dent",
      next: "Suivant",
      today: "Aujourd'hui",
      year: "AnnÃ©e",
      month: "Mois",
      week: "Semaine",
      day: "Jour",
      list: "Mon planning"
    },
    weekLabel: "Sem.",
    allDayHtml: "Toute la<br/>journÃ©e",
    eventLimitText: "en plus",
    noEventsMessage: "Aucun Ã©vÃ©nement Ã  afficher"
  };

  var _m25 = {
    code: "fr-ch",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "PrÃ©cÃ©dent",
      next: "Suivant",
      today: "Courant",
      year: "AnnÃ©e",
      month: "Mois",
      week: "Semaine",
      day: "Jour",
      list: "Mon planning"
    },
    weekLabel: "Sm",
    allDayHtml: "Toute la<br/>journÃ©e",
    eventLimitText: "en plus",
    noEventsMessage: "Aucun Ã©vÃ©nement Ã  afficher"
  };

  var _m26 = {
    code: "fr",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "PrÃ©cÃ©dent",
      next: "Suivant",
      today: "Aujourd'hui",
      year: "AnnÃ©e",
      month: "Mois",
      week: "Semaine",
      day: "Jour",
      list: "Mon planning"
    },
    weekLabel: "Sem.",
    allDayHtml: "Toute la<br/>journÃ©e",
    eventLimitText: "en plus",
    noEventsMessage: "Aucun Ã©vÃ©nement Ã  afficher"
  };

  var _m27 = {
    code: "gl",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Ant",
      next: "Seg",
      today: "Hoxe",
      month: "Mes",
      week: "Semana",
      day: "DÃ­a",
      list: "Axenda"
    },
    weekLabel: "Sm",
    allDayHtml: "Todo<br/>o dÃ­a",
    eventLimitText: "mÃ¡is",
    noEventsMessage: "Non hai eventos para amosar"
  };

  var _m28 = {
    code: "he",
    dir: 'rtl',
    buttonText: {
      prev: "×”×§×•×“×",
      next: "×”×‘×",
      today: "×”×™×•×",
      month: "×—×•×“×©",
      week: "×©×‘×•×¢",
      day: "×™×•×",
      list: "×¡×“×¨ ×™×•×"
    },
    allDayText: "×›×œ ×”×™×•×",
    eventLimitText: "××—×¨",
    noEventsMessage: "××™×Ÿ ××™×¨×•×¢×™× ×œ×”×¦×’×”",
    weekLabel: "×©×‘×•×¢"
  };

  var _m29 = {
    code: "hi",
    week: {
      dow: 0,
      doy: 6 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "à¤ªà¤¿à¤›à¤²à¤¾",
      next: "à¤…à¤—à¤²à¤¾",
      today: "à¤†à¤œ",
      month: "à¤®à¤¹à¥€à¤¨à¤¾",
      week: "à¤¸à¤ªà¥à¤¤à¤¾à¤¹",
      day: "à¤¦à¤¿à¤¨",
      list: "à¤•à¤¾à¤°à¥à¤¯à¤¸à¥‚à¤šà¥€"
    },
    weekLabel: "à¤¹à¤«à¥à¤¤à¤¾",
    allDayText: "à¤¸à¤­à¥€ à¤¦à¤¿à¤¨",
    eventLimitText: function (n) {
      return "+à¤…à¤§à¤¿à¤• " + n;
    },
    noEventsMessage: "à¤•à¥‹à¤ˆ à¤˜à¤Ÿà¤¨à¤¾à¤“à¤‚ à¤•à¥‹ à¤ªà¥à¤°à¤¦à¤°à¥à¤¶à¤¿à¤¤ à¤•à¤°à¤¨à¥‡ à¤•à¥‡ à¤²à¤¿à¤"
  };

  var _m30 = {
    code: "hr",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "PrijaÅ¡nji",
      next: "SljedeÄ‡i",
      today: "Danas",
      month: "Mjesec",
      week: "Tjedan",
      day: "Dan",
      list: "Raspored"
    },
    weekLabel: "Tje",
    allDayText: "Cijeli dan",
    eventLimitText: function (n) {
      return "+ joÅ¡ " + n;
    },
    noEventsMessage: "Nema dogaÄ‘aja za prikaz"
  };

  var _m31 = {
    code: "hu",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "vissza",
      next: "elÅ‘re",
      today: "ma",
      month: "HÃ³nap",
      week: "HÃ©t",
      day: "Nap",
      list: "NaplÃ³"
    },
    weekLabel: "HÃ©t",
    allDayText: "EgÃ©sz nap",
    eventLimitText: "tovÃ¡bbi",
    noEventsMessage: "Nincs megjelenÃ­thetÅ‘ esemÃ©ny"
  };

  var _m32 = {
    code: "id",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "mundur",
      next: "maju",
      today: "hari ini",
      month: "Bulan",
      week: "Minggu",
      day: "Hari",
      list: "Agenda"
    },
    weekLabel: "Mg",
    allDayHtml: "Sehari<br/>penuh",
    eventLimitText: "lebih",
    noEventsMessage: "Tidak ada acara untuk ditampilkan"
  };

  var _m33 = {
    code: "is",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Fyrri",
      next: "NÃ¦sti",
      today: "Ã dag",
      month: "MÃ¡nuÃ°ur",
      week: "Vika",
      day: "Dagur",
      list: "DagskrÃ¡"
    },
    weekLabel: "Vika",
    allDayHtml: "Allan<br/>daginn",
    eventLimitText: "meira",
    noEventsMessage: "Engir viÃ°burÃ°ir til aÃ° sÃ½na"
  };

  var _m34 = {
    code: "it",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Prec",
      next: "Succ",
      today: "Oggi",
      month: "Mese",
      week: "Settimana",
      day: "Giorno",
      list: "Agenda"
    },
    weekLabel: "Sm",
    allDayHtml: "Tutto il<br/>giorno",
    eventLimitText: function (n) {
      return "+altri " + n;
    },
    noEventsMessage: "Non ci sono eventi da visualizzare"
  };

  var _m35 = {
    code: "ja",
    buttonText: {
      prev: "å‰",
      next: "æ¬¡",
      today: "ä»Šæ—¥",
      month: "æœˆ",
      week: "é€±",
      day: "æ—¥",
      list: "äºˆå®šãƒªã‚¹ãƒˆ"
    },
    weekLabel: "é€±",
    allDayText: "çµ‚æ—¥",
    eventLimitText: function (n) {
      return "ä»– " + n + " ä»¶";
    },
    noEventsMessage: "è¡¨ç¤ºã™ã‚‹äºˆå®šã¯ã‚ã‚Šã¾ã›ã‚“"
  };

  var _m36 = {
    code: "ka",
    week: {
      dow: 1,
      doy: 7
    },
    buttonText: {
      prev: "áƒ¬áƒ˜áƒœáƒ",
      next: "áƒ¨áƒ”áƒ›áƒ“áƒ”áƒ’áƒ˜",
      today: "áƒ“áƒ¦áƒ”áƒ¡",
      month: "áƒ—áƒ•áƒ”",
      week: "áƒ™áƒ•áƒ˜áƒ áƒ",
      day: "áƒ“áƒ¦áƒ”",
      list: "áƒ“áƒ¦áƒ˜áƒ¡ áƒ¬áƒ”áƒ¡áƒ áƒ˜áƒ’áƒ˜"
    },
    weekLabel: "áƒ™áƒ•",
    allDayText: "áƒ›áƒ—áƒ”áƒšáƒ˜ áƒ“áƒ¦áƒ”",
    eventLimitText: function (n) {
      return "+ áƒ™áƒ˜áƒ“áƒ”áƒ• " + n;
    },
    noEventsMessage: "áƒ¦áƒáƒœáƒ˜áƒ¡áƒ«áƒ˜áƒ”áƒ‘áƒ”áƒ‘áƒ˜ áƒáƒ  áƒáƒ áƒ˜áƒ¡"
  };

  var _m37 = {
    code: "kk",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "ÐÐ»Ð´Ñ‹Ò£Ò“Ñ‹",
      next: "ÐšÐµÐ»ÐµÑÑ–",
      today: "Ð‘Ò¯Ð³Ñ–Ð½",
      month: "ÐÐ¹",
      week: "ÐÐ¿Ñ‚Ð°",
      day: "ÐšÒ¯Ð½",
      list: "ÐšÒ¯Ð½ Ñ‚Ó™Ñ€Ñ‚Ñ–Ð±Ñ–"
    },
    weekLabel: "ÐÐµ",
    allDayText: "ÐšÒ¯Ð½Ñ– Ð±Ð¾Ð¹Ñ‹",
    eventLimitText: function (n) {
      return "+ Ñ‚Ð°Ò“Ñ‹ " + n;
    },
    noEventsMessage: "ÐšÓ©Ñ€ÑÐµÑ‚Ñƒ Ò¯ÑˆÑ–Ð½ Ð¾Ò›Ð¸Ò“Ð°Ð»Ð°Ñ€ Ð¶Ð¾Ò›"
  };

  var _m38 = {
    code: "ko",
    buttonText: {
      prev: "ì´ì „ë‹¬",
      next: "ë‹¤ìŒë‹¬",
      today: "ì˜¤ëŠ˜",
      month: "ì›”",
      week: "ì£¼",
      day: "ì¼",
      list: "ì¼ì •ëª©ë¡"
    },
    weekLabel: "ì£¼",
    allDayText: "ì¢…ì¼",
    eventLimitText: "ê°œ",
    noEventsMessage: "ì¼ì •ì´ ì—†ìŠµë‹ˆë‹¤"
  };

  var _m39 = {
    code: "lb",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "ZrÃ©ck",
      next: "Weider",
      today: "Haut",
      month: "Mount",
      week: "Woch",
      day: "Dag",
      list: "Terminiwwersiicht"
    },
    weekLabel: "W",
    allDayText: "Ganzen Dag",
    eventLimitText: "mÃ©i",
    noEventsMessage: "Nee Evenementer ze affichÃ©ieren"
  };

  var _m40 = {
    code: "lt",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Atgal",
      next: "Pirmyn",
      today: "Å iandien",
      month: "MÄ—nuo",
      week: "SavaitÄ—",
      day: "Diena",
      list: "DarbotvarkÄ—"
    },
    weekLabel: "SAV",
    allDayText: "VisÄ… dienÄ…",
    eventLimitText: "daugiau",
    noEventsMessage: "NÄ—ra Ä¯vykiÅ³ rodyti"
  };

  var _m41 = {
    code: "lv",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Iepr.",
      next: "NÄk.",
      today: "Å odien",
      month: "MÄ“nesis",
      week: "NedÄ“Ä¼a",
      day: "Diena",
      list: "Dienas kÄrtÄ«ba"
    },
    weekLabel: "Ned.",
    allDayText: "Visu dienu",
    eventLimitText: function (n) {
      return "+vÄ“l " + n;
    },
    noEventsMessage: "Nav notikumu"
  };

  var _m42 = {
    code: "mk",
    buttonText: {
      prev: "Ð¿Ñ€ÐµÑ‚Ñ…Ð¾Ð´Ð½Ð¾",
      next: "ÑÐ»ÐµÐ´Ð½Ð¾",
      today: "Ð”ÐµÐ½ÐµÑ",
      month: "ÐœÐµÑÐµÑ†",
      week: "ÐÐµÐ´ÐµÐ»Ð°",
      day: "Ð”ÐµÐ½",
      list: "Ð“Ñ€Ð°Ñ„Ð¸Ðº"
    },
    weekLabel: "Ð¡ÐµÐ´",
    allDayText: "Ð¦ÐµÐ» Ð´ÐµÐ½",
    eventLimitText: function (n) {
      return "+Ð¿Ð¾Ð²ÐµÑœÐµ " + n;
    },
    noEventsMessage: "ÐÐµÐ¼Ð° Ð½Ð°ÑÑ‚Ð°Ð½Ð¸ Ð·Ð° Ð¿Ñ€Ð¸ÐºÐ°Ð¶ÑƒÐ²Ð°ÑšÐµ"
  };

  var _m43 = {
    code: "ms",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "Sebelum",
      next: "Selepas",
      today: "hari ini",
      month: "Bulan",
      week: "Minggu",
      day: "Hari",
      list: "Agenda"
    },
    weekLabel: "Mg",
    allDayText: "Sepanjang hari",
    eventLimitText: function (n) {
      return "masih ada " + n + " acara";
    },
    noEventsMessage: "Tiada peristiwa untuk dipaparkan"
  };

  var _m44 = {
    code: "nb",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Forrige",
      next: "Neste",
      today: "I dag",
      month: "MÃ¥ned",
      week: "Uke",
      day: "Dag",
      list: "Agenda"
    },
    weekLabel: "Uke",
    allDayText: "Hele dagen",
    eventLimitText: "til",
    noEventsMessage: "Ingen hendelser Ã¥ vise"
  };

  var _m45 = {
    code: "nl",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Voorgaand",
      next: "Volgende",
      today: "Vandaag",
      year: "Jaar",
      month: "Maand",
      week: "Week",
      day: "Dag",
      list: "Agenda"
    },
    allDayText: "Hele dag",
    eventLimitText: "extra",
    noEventsMessage: "Geen evenementen om te laten zien"
  };

  var _m46 = {
    code: "nn",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "FÃ¸rre",
      next: "Neste",
      today: "I dag",
      month: "MÃ¥nad",
      week: "Veke",
      day: "Dag",
      list: "Agenda"
    },
    weekLabel: "Veke",
    allDayText: "Heile dagen",
    eventLimitText: "til",
    noEventsMessage: "Ingen hendelser Ã¥ vise"
  };

  var _m47 = {
    code: "pl",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Poprzedni",
      next: "NastÄ™pny",
      today: "DziÅ›",
      month: "MiesiÄ…c",
      week: "TydzieÅ„",
      day: "DzieÅ„",
      list: "Plan dnia"
    },
    weekLabel: "Tydz",
    allDayText: "CaÅ‚y dzieÅ„",
    eventLimitText: "wiÄ™cej",
    noEventsMessage: "Brak wydarzeÅ„ do wyÅ›wietlenia"
  };

  var _m48 = {
    code: "pt-br",
    buttonText: {
      prev: "Anterior",
      next: "PrÃ³ximo",
      today: "Hoje",
      month: "MÃªs",
      week: "Semana",
      day: "Dia",
      list: "Compromissos"
    },
    weekLabel: "Sm",
    allDayText: "dia inteiro",
    eventLimitText: function (n) {
      return "mais +" + n;
    },
    noEventsMessage: "NÃ£o hÃ¡ eventos para mostrar"
  };

  var _m49 = {
    code: "pt",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "Anterior",
      next: "Seguinte",
      today: "Hoje",
      month: "MÃªs",
      week: "Semana",
      day: "Dia",
      list: "Agenda"
    },
    weekLabel: "Sem",
    allDayText: "Todo o dia",
    eventLimitText: "mais",
    noEventsMessage: "NÃ£o hÃ¡ eventos para mostrar"
  };

  var _m50 = {
    code: "ro",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "precedentÄƒ",
      next: "urmÄƒtoare",
      today: "Azi",
      month: "LunÄƒ",
      week: "SÄƒptÄƒmÃ¢nÄƒ",
      day: "Zi",
      list: "AgendÄƒ"
    },
    weekLabel: "SÄƒpt",
    allDayText: "ToatÄƒ ziua",
    eventLimitText: function (n) {
      return "+alte " + n;
    },
    noEventsMessage: "Nu existÄƒ evenimente de afiÈ™at"
  };

  var _m51 = {
    code: "ru",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "ÐŸÑ€ÐµÐ´",
      next: "Ð¡Ð»ÐµÐ´",
      today: "Ð¡ÐµÐ³Ð¾Ð´Ð½Ñ",
      month: "ÐœÐµÑÑÑ†",
      week: "ÐÐµÐ´ÐµÐ»Ñ",
      day: "Ð”ÐµÐ½ÑŒ",
      list: "ÐŸÐ¾Ð²ÐµÑÑ‚ÐºÐ° Ð´Ð½Ñ"
    },
    weekLabel: "ÐÐµÐ´",
    allDayText: "Ð’ÐµÑÑŒ Ð´ÐµÐ½ÑŒ",
    eventLimitText: function (n) {
      return "+ ÐµÑ‰Ñ‘ " + n;
    },
    noEventsMessage: "ÐÐµÑ‚ ÑÐ¾Ð±Ñ‹Ñ‚Ð¸Ð¹ Ð´Ð»Ñ Ð¾Ñ‚Ð¾Ð±Ñ€Ð°Ð¶ÐµÐ½Ð¸Ñ"
  };

  var _m52 = {
    code: "sk",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "PredchÃ¡dzajÃºci",
      next: "NasledujÃºci",
      today: "Dnes",
      month: "Mesiac",
      week: "TÃ½Å¾deÅˆ",
      day: "DeÅˆ",
      list: "Rozvrh"
    },
    weekLabel: "Ty",
    allDayText: "CelÃ½ deÅˆ",
    eventLimitText: function (n) {
      return "+ÄalÅ¡ie: " + n;
    },
    noEventsMessage: "Å½iadne akcie na zobrazenie"
  };

  var _m53 = {
    code: "sl",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "PrejÅ¡nji",
      next: "Naslednji",
      today: "Trenutni",
      month: "Mesec",
      week: "Teden",
      day: "Dan",
      list: "Dnevni red"
    },
    weekLabel: "Teden",
    allDayText: "Ves dan",
    eventLimitText: "veÄ",
    noEventsMessage: "Ni dogodkov za prikaz"
  };

  var _m54 = {
    code: "sq",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "mbrapa",
      next: "PÃ«rpara",
      today: "sot",
      month: "Muaj",
      week: "JavÃ«",
      day: "DitÃ«",
      list: "ListÃ«"
    },
    weekLabel: "Ja",
    allDayHtml: "GjithÃ«<br/>ditÃ«n",
    eventLimitText: function (n) {
      return "+mÃ« tepÃ«r " + n;
    },
    noEventsMessage: "Nuk ka evente pÃ«r tÃ« shfaqur"
  };

  var _m55 = {
    code: "sr-cyrl",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "ÐŸÑ€ÐµÑ‚Ñ…Ð¾Ð´Ð½Ð°",
      next: "ÑÐ»ÐµÐ´ÐµÑ›Ð¸",
      today: "Ð”Ð°Ð½Ð°Ñ",
      month: "ÐœÐµÑÐµÑ†",
      week: "ÐÐµÐ´ÐµÑ™Ð°",
      day: "Ð”Ð°Ð½",
      list: "ÐŸÐ»Ð°Ð½ÐµÑ€"
    },
    weekLabel: "Ð¡ÐµÐ´",
    allDayText: "Ð¦ÐµÐ¾ Ð´Ð°Ð½",
    eventLimitText: function (n) {
      return "+ Ñ˜Ð¾Ñˆ " + n;
    },
    noEventsMessage: "ÐÐµÐ¼Ð° Ð´Ð¾Ð³Ð°Ñ’Ð°Ñ˜Ð° Ð·Ð° Ð¿Ñ€Ð¸ÐºÐ°Ð·"
  };

  var _m56 = {
    code: "sr",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "Prethodna",
      next: "SledecÌi",
      today: "Danas",
      month: "MÐµsÐµc",
      week: "NÐµdÐµlja",
      day: "Dan",
      list: "PlanÐµr"
    },
    weekLabel: "Sed",
    allDayText: "CÐµo dan",
    eventLimitText: function (n) {
      return "+ joÅ¡ " + n;
    },
    noEventsMessage: "NÐµma dogaÄ‘aja za prikaz"
  };

  var _m57 = {
    code: "sv",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "FÃ¶rra",
      next: "NÃ¤sta",
      today: "Idag",
      month: "MÃ¥nad",
      week: "Vecka",
      day: "Dag",
      list: "Program"
    },
    weekLabel: "v.",
    allDayText: "Heldag",
    eventLimitText: "till",
    noEventsMessage: "Inga hÃ¤ndelser att visa"
  };

  var _m58 = {
    code: "th",
    buttonText: {
      prev: "à¸¢à¹‰à¸­à¸™",
      next: "à¸–à¸±à¸”à¹„à¸›",
      today: "à¸§à¸±à¸™à¸™à¸µà¹‰",
      month: "à¹€à¸”à¸·à¸­à¸™",
      week: "à¸ªà¸±à¸›à¸”à¸²à¸«à¹Œ",
      day: "à¸§à¸±à¸™",
      list: "à¹à¸œà¸™à¸‡à¸²à¸™"
    },
    allDayText: "à¸•à¸¥à¸­à¸”à¸§à¸±à¸™",
    eventLimitText: "à¹€à¸žà¸´à¹ˆà¸¡à¹€à¸•à¸´à¸¡",
    noEventsMessage: "à¹„à¸¡à¹ˆà¸¡à¸µà¸à¸´à¸ˆà¸à¸£à¸£à¸¡à¸—à¸µà¹ˆà¸ˆà¸°à¹à¸ªà¸”à¸‡"
  };

  var _m59 = {
    code: "tr",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "geri",
      next: "ileri",
      today: "bugÃ¼n",
      month: "Ay",
      week: "Hafta",
      day: "GÃ¼n",
      list: "Ajanda"
    },
    weekLabel: "Hf",
    allDayText: "TÃ¼m gÃ¼n",
    eventLimitText: "daha fazla",
    noEventsMessage: "GÃ¶sterilecek etkinlik yok"
  };

  var _m60 = {
    code: "uk",
    week: {
      dow: 1,
      doy: 7 // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "ÐŸÐ¾Ð¿ÐµÑ€ÐµÐ´Ð½Ñ–Ð¹",
      next: "Ð´Ð°Ð»Ñ–",
      today: "Ð¡ÑŒÐ¾Ð³Ð¾Ð´Ð½Ñ–",
      month: "ÐœÑ–ÑÑÑ†ÑŒ",
      week: "Ð¢Ð¸Ð¶Ð´ÐµÐ½ÑŒ",
      day: "Ð”ÐµÐ½ÑŒ",
      list: "ÐŸÐ¾Ñ€ÑÐ´Ð¾Ðº Ð´ÐµÐ½Ð½Ð¸Ð¹"
    },
    weekLabel: "Ð¢Ð¸Ð¶",
    allDayText: "Ð£Ð²ÐµÑÑŒ Ð´ÐµÐ½ÑŒ",
    eventLimitText: function (n) {
      return "+Ñ‰Ðµ " + n + "...";
    },
    noEventsMessage: "ÐÐµÐ¼Ð°Ñ” Ð¿Ð¾Ð´Ñ–Ð¹ Ð´Ð»Ñ Ð²Ñ–Ð´Ð¾Ð±Ñ€Ð°Ð¶ÐµÐ½Ð½Ñ"
  };

  var _m61 = {
    code: "vi",
    week: {
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "TrÆ°á»›c",
      next: "Tiáº¿p",
      today: "HÃ´m nay",
      month: "ThÃ¡ng",
      week: "TuÃ¢Ì€n",
      day: "NgÃ y",
      list: "Lá»‹ch biá»ƒu"
    },
    weekLabel: "Tu",
    allDayText: "Cáº£ ngÃ y",
    eventLimitText: function (n) {
      return "+ thÃªm " + n;
    },
    noEventsMessage: "KhÃ´ng cÃ³ sá»± kiá»‡n Ä‘á»ƒ hiá»ƒn thá»‹"
  };

  var _m62 = {
    code: "zh-cn",
    week: {
      // GB/T 7408-1994ã€Šæ•°æ®å…ƒå’Œäº¤æ¢æ ¼å¼Â·ä¿¡æ¯äº¤æ¢Â·æ—¥æœŸå’Œæ—¶é—´è¡¨ç¤ºæ³•ã€‹ä¸ŽISO 8601:1988ç­‰æ•ˆ
      dow: 1,
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
      prev: "ä¸Šæœˆ",
      next: "ä¸‹æœˆ",
      today: "ä»Šå¤©",
      month: "æœˆ",
      week: "å‘¨",
      day: "æ—¥",
      list: "æ—¥ç¨‹"
    },
    weekLabel: "å‘¨",
    allDayText: "å…¨å¤©",
    eventLimitText: function (n) {
      return "å¦å¤– " + n + " ä¸ª";
    },
    noEventsMessage: "æ²¡æœ‰äº‹ä»¶æ˜¾ç¤º"
  };

  var _m63 = {
    code: "zh-tw",
    buttonText: {
      prev: "ä¸Šæœˆ",
      next: "ä¸‹æœˆ",
      today: "ä»Šå¤©",
      month: "æœˆ",
      week: "é€±",
      day: "å¤©",
      list: "æ´»å‹•åˆ—è¡¨"
    },
    weekLabel: "å‘¨",
    allDayText: "æ•´å¤©",
    eventLimitText: 'é¡¯ç¤ºæ›´å¤š',
    noEventsMessage: "æ²¡æœ‰ä»»ä½•æ´»å‹•"
  };

  var _arrayEntry = [
    _m0, _m1, _m2, _m3, _m4, _m5, _m6, _m7, _m8, _m9, _m10, _m11, _m12, _m13, _m14, _m15, _m16, _m17, _m18, _m19, _m20, _m21, _m22, _m23, _m24, _m25, _m26, _m27, _m28, _m29, _m30, _m31, _m32, _m33, _m34, _m35, _m36, _m37, _m38, _m39, _m40, _m41, _m42, _m43, _m44, _m45, _m46, _m47, _m48, _m49, _m50, _m51, _m52, _m53, _m54, _m55, _m56, _m57, _m58, _m59, _m60, _m61, _m62, _m63
  ];

  return _arrayEntry;

}));
