define('ember-truth-helpers/utils/truth-convert', ['exports', 'ember'], function (exports, Ember) {

  'use strict';



  exports['default'] = truthConvert;
  function truthConvert(result) {
    var truthy = result && Ember['default'].get(result, 'isTruthy');
    if (typeof truthy === 'boolean') {
      return truthy;
    }

    if (Ember['default'].isArray(result)) {
      return Ember['default'].get(result, 'length') !== 0;
    } else {
      return !!result;
    }
  }

});