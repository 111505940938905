define('ember-uploader/core', ['exports', 'ember', 'ember-uploader/version'], function (exports, Ember, VERSION) {

  'use strict';

  var EmberUploader = Ember['default'].Namespace.create({
    VERSION: VERSION['default']
  });

  if (Ember['default'].libraries) {
    Ember['default'].libraries.registerCoreLibrary('Ember Uploader', EmberUploader.VERSION);
  }

  exports['default'] = EmberUploader;

});