define('ember-cli-tooltipster/components/tool-tipster', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var run = Ember['default'].run;
  var observer = Ember['default'].observer;
  var on = Ember['default'].on;
  var isEmpty = Ember['default'].isEmpty;
  var $ = Ember['default'].$;
  var SafeString = Ember['default'].Handlebars.SafeString;

  exports['default'] = Ember['default'].Component.extend({
    tooltipsterInstance: null,

    attributeBindings: ['title'],

    /**
     * Set how tooltips should be activated and closed.
     * Default: 'hover'
     * Options: [hover, click]
     * @type {String}
     */
    triggerEvent: 'hover',

    tooltipsterOptions: ['animation', 'animationDuration', 'arrow', 'content', 'contentAsHTML', 'contentCloning', 'debug', 'delay', 'delayTouch', 'distance', 'IEmin', 'interactive', 'maxWidth', 'minIntersection', 'minWidth', 'repositionOnScroll', 'restoration', 'selfDestruction', 'side', 'timer', 'theme', 'trackerInterval', 'trackOrigin', 'trackTooltip', 'triggerClose', 'triggerOpen', 'updateAnimation', 'viewportAware', 'zIndex'],

    _initializeTooltipster: on('didInsertElement', function () {
      var options = this._getStandardOptions();
      var componentElement = this.$();
      componentElement.tooltipster(options);
      this.set('tooltipsterInstance', componentElement.tooltipster('instance'));
    }),

    _getStandardOptions: function _getStandardOptions() {
      var _this = this;

      var options = {};
      var content = this.get('content') || this.get('title');
      this.get('tooltipsterOptions').forEach(function (option) {
        if (!isEmpty(_this.get(option))) {
          options[option] = _this.get(option);
        }
      });
      options.trigger = this.get('triggerEvent');
      // Handle SafeString
      if (content instanceof SafeString) {
        options.content = content.toString();
      }

      ['functionInit', 'functionBefore', 'functionReady', 'functionAfter', 'functionFormat', 'functionPosition'].forEach(function (fn) {
        options[fn] = $.proxy(_this[fn], _this);
      });
      return options;
    },

    _onContentDidChange: observer('content', 'title', function () {
      var _this2 = this;

      run.scheduleOnce('afterRender', this, function () {
        var content = _this2.get('content') || _this2.get('title');
        if (content instanceof SafeString) {
          content = content.toString();
        }
        _this2.get('tooltipsterInstance').content(content);
      });
    }),

    _destroyTooltipster: on('willDestroyElement', function () {
      if (this.$().data('tooltipster-ns')) {
        this.$().tooltipster('destroy');
      }
      this.set('tooltipsterInstance', null);
      this.$().off();
    })
  });

});