define('ember-uploader/components/file-field', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(Ember['default'].Evented, {
    tagName: 'input',
    type: 'file',
    attributeBindings: ['name', 'disabled', 'form', 'type', 'accept', 'autofocus', 'required', 'multiple'],
    multiple: false,
    change: function change(event) {
      var input = event.target;
      if (!Ember['default'].isEmpty(input.files)) {
        this.trigger('filesDidChange', input.files);
      }
    }
  });

});