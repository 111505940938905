define('ember-select-guru/components/multi-value-component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Component = Ember['default'].Component;

  exports['default'] = Component.extend({
    tagName: 'li',
    classNames: 'multi-value__option',
    actions: {
      onRemoveClick: function onRemoveClick() {
        this.sendAction('onRemoveClick', this.get('value'));
      }
    }
  });

});