define('ember-select-guru/utils/array-utils', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    intersection: function intersection() {
      var first = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];
      var second = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      return first.filter(function (item) {
        return second.indexOf(item) !== -1;
      });
    },
    difference: function difference() {
      var first = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];
      var second = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      var secondSet = new Set(second);
      return first.filter(function (item) {
        return !secondSet.has(item);
      });
    }
  };

});