define('ember-aupac-typeahead/components/aupac-typeahead', ['exports', 'ember', 'ember-aupac-typeahead/templates/components/aupac-typeahead/footer', 'ember-aupac-typeahead/templates/components/aupac-typeahead/header', 'ember-aupac-typeahead/templates/components/aupac-typeahead/not-found', 'ember-aupac-typeahead/templates/components/aupac-typeahead/pending', 'ember-aupac-typeahead/templates/components/aupac-typeahead/suggestion'], function (exports, Ember, footerTemplate, headerTemplate, notFoundTemplate, pendingTemplate, suggestionTemplate) {

  'use strict';

  var observer = Ember['default'].observer;
  var isNone = Ember['default'].isNone;
  var run = Ember['default'].run;
  var debug = Ember['default'].debug;
  var Component = Ember['default'].Component;

  var Key = {
    BACKSPACE: 8,
    DELETE: 46,
    ENTER: 13
  };

  exports['default'] = Component.extend({
    //input tag attributes
    tagName: 'input',
    classNames: ['aupac-typeahead'],
    attributeBindings: ['disabled', 'placeholder', 'name'],
    disabled: false, //@public
    placeholder: 'Search', //@public
    name: '', //@public

    //Actions
    action: Ember['default'].K, //@public
    selection: null, //@public
    source: Ember['default'].K, //@public

    //typeahead.js Customizations
    highlight: true, //@public
    hint: true, //@public
    minLength: 2, //@public
    typeaheadClassNames: {}, //@public
    autoFocus: false, //@public
    limit: 15, //@public
    async: false, //@public
    datasetName: '', //@public
    allowFreeInput: false, //@public

    //HtmlBars Templates
    suggestionTemplate: suggestionTemplate['default'], //@public
    notFoundTemplate: notFoundTemplate['default'], //@public
    pendingTemplate: pendingTemplate['default'], //@public
    headerTemplate: headerTemplate['default'], //@public
    footerTemplate: footerTemplate['default'], //@public

    //Private
    _typeahead: null,

    /**
     * @public
     * @param selection - the item selected by the user
     * @returns {*}
     */
    display: function display(selection) {
      return selection;
    },

    /**
     * @public
     * @param selection the item selected by the user
     */
    transformSelection: function transformSelection(selection) {
      return selection;
    },

    /**
     * @public
     * @param selection the item selected by the user
     */
    setValue: function setValue(selection) {
      selection = this.transformSelection(selection);
      if (selection) {
        this.get('_typeahead').typeahead('val', selection);
      } else {
        this.get('_typeahead').typeahead('val', '');
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initializeTypeahead();
      if (this.get('autoFocus') === true) {
        this.get('_typeahead').focus();
      }
      this.addObserver('disabled', this.disabledStateChanged);
    },

    disabledStateChanged: function disabledStateChanged() {
      //Toggling the disabled attribute on the controller does not update the hint, need to do this manually.
      this.$().parent().find('input.tt-hint').prop('disabled', this.get('disabled'));
    },

    initializeTypeahead: function initializeTypeahead() {
      var _this = this;

      var self = this;
      //Setup the typeahead
      var t = this.$().typeahead({
        highlight: this.get('highlight'),
        hint: this.get('hint'),
        minLength: this.get('minLength'),
        classNames: this.get('typeaheadClassNames')
      }, {
        component: this,
        name: this.get('datasetName') || 'default',
        display: this.get('display'),
        async: this.get('async'),
        limit: this.get('limit'),
        source: this.get('source'),
        templates: {
          suggestion: function suggestion(model) {
            var item = Component.create({
              model: model,
              layout: self.get('suggestionTemplate')
            }).createElement();
            return item.element;
          },
          notFound: function notFound(query) {
            var item = Component.create({
              query: query,
              layout: self.get('notFoundTemplate')
            }).createElement();
            return item.element;
          },
          pending: function pending(query) {
            var item = Component.create({
              query: query,
              layout: self.get('pendingTemplate')
            }).createElement();
            return item.element;
          },
          header: function header(query, suggestions) {
            var item = Component.create({
              query: query,
              suggestions: suggestions,
              layout: self.get('headerTemplate')
            }).createElement();
            return item.element;
          },
          footer: function footer(query, suggestions) {
            var item = Component.create({
              query: query,
              suggestions: suggestions,
              layout: self.get('footerTemplate')
            }).createElement();
            return item.element;
          }
        }
      });
      this.set('_typeahead', t);

      // Set selected object
      t.on('typeahead:autocompleted', run.bind(this, function (jqEvent, suggestionObject /*, nameOfDatasetSuggestionBelongsTo*/) {
        _this.set('selection', suggestionObject);
        _this.sendAction('action', suggestionObject);
      }));

      t.on('typeahead:selected', run.bind(this, function (jqEvent, suggestionObject /*, nameOfDatasetSuggestionBelongsTo*/) {
        _this.set('selection', suggestionObject);
        _this.sendAction('action', suggestionObject);
      }));

      t.on('keyup', run.bind(this, function (jqEvent) {
        //Handle the case whereby the user presses the delete or backspace key, in either case
        //the selection is no longer valid.
        if (jqEvent.which === Key.BACKSPACE || jqEvent.which === Key.DELETE) {
          debug("Removing model");
          var value = _this.get('_typeahead').typeahead('val'); //cache value
          _this.set('selection', null);
          _this.sendAction('action', null);
          _this.setValue(value); //restore the text, thus allowing the user to make corrections
        } else if (jqEvent.which === Key.ENTER) {
            t.trigger("focusout");
          }
      }));

      t.on('focusout', run.bind(this, function () /*jqEvent*/{
        //the user has now left the control, update display with current binding or reset to blank
        var model = _this.get('selection');
        if (_this.get('allowFreeInput')) {
          var value = _this.get('_typeahead').typeahead('val');
          _this.set('selection', value);
          _this.sendAction('action', value);
        } else if (model) {
          _this.setValue(model);
        } else {
          _this.setValue(null);
        }
      }));
    },

    selectionUpdated: observer('selection', '_typeahead', function () {
      var selection = this.get('selection');
      if (isNone(selection)) {
        this.setValue(null);
      } else {
        this.setValue(selection);
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('_typeahead').typeahead('destroy');
    }

  });

});