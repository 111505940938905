define('ember-uploader/index', ['exports', 'ember', 'ember-uploader/core', 'ember-uploader/uploaders', 'ember-uploader/components'], function (exports, Ember, EmberUploader, uploaders, components) {

  'use strict';

  if (Ember['default'].VERSION.match(/^1/)) {
    Ember['default'].Logger.warn('This version of Ember Uploader has not been tested on Ember 1.x. Use at your own risk.');
  }EmberUploader['default'].Uploader = uploaders.Uploader;
  EmberUploader['default'].S3Uploader = uploaders.S3Uploader;EmberUploader['default'].FileField = components.FileField;

  Ember['default'].lookup.EmberUploader = EmberUploader['default'];

  exports['default'] = EmberUploader['default'];

});