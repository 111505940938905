define('ember-select-guru/components/ember-select-guru', ['exports', 'ember', 'ember-select-guru/utils/array-utils'], function (exports, Ember, ArrayUtils) {

  'use strict';

  var intersection = ArrayUtils['default'].intersection;
  var difference = ArrayUtils['default'].difference;
  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var observer = Ember['default'].observer;
  var get = Ember['default'].get;
  var run = Ember['default'].run;

  exports['default'] = Component.extend({
    isPending: false,
    hasFailed: false,
    remoteData: false,
    queryTerm: null,
    multiple: false,
    searchPlaceholder: 'Type to search...',
    placeholder: 'Click to select...',
    pendingComponent: 'pending-component',
    failureComponent: 'failure-component',
    optionComponent: 'option-component',
    noOptionsComponent: 'no-options-component',
    singleValueComponent: 'single-value-component',
    multiValueComponent: 'multi-value-component',
    searchKey: 'name',
    classNames: 'ember-select-guru',
    classNameBindings: ['isExpanded:ember-select-guru__expanded'],
    hasOptions: computed.notEmpty('_options'),
    hasValue: computed.notEmpty('value'),
    queryTermObserver: observer('queryTerm', function () {
      var _this = this;

      Ember['default'].run.once(function () {
        _this.setProperties({
          isPending: false,
          hasFailed: false,
          remoteData: false
        });
        var result = _this.attrs.onSearchInputChange && _this.attrs.onSearchInputChange(_this.get('queryTerm'));
        if (result === undefined || result === null) {
          // handle if result is undefined (internal search)
          _this._handleAttrsChange();
          if (!_this.get('queryTerm')) {
            return;
          }
          var possibleOptions = _this._searchForOptions();
          _this.set('_options', possibleOptions);
        } else if ('function' === typeof result.then) {
          // handle if result is a promise
          _this.set('isPending', true);
          _this.set('_remoteData', true);
          result.then(function () {
            _this.set('isPending', false);
          }, function () {
            _this.set('hasFailed', true);
            _this.set('isPending', false);
          });
        } else if (Array.isArray(result)) {
          // handle if result is an array (external search)
          var options = intersection(result, _this.get('options'));
          _this.set('_options', options);
        }
      });
    }),
    attrsObserver: observer('value', 'value.[]', 'options.[]', function () {
      var _this2 = this;

      Ember['default'].run.once(function () {
        _this2._handleAttrsChange();
      });
    }),
    actions: {
      onOptionClick: function onOptionClick(option) {
        if (this.get('multiple')) {
          // handle multiple selection
          this.get('_value').pushObject(option);
          this.attrs.onSelect(this.get('_value'));
        } else {
          // handle single selection
          this.attrs.onSelect(option);
        }
      },
      onRemoveValueClick: function onRemoveValueClick(option) {
        this.get('_value').removeObject(option);
        this.attrs.onSelect(this.get('_value'));
      },
      expandComponent: function expandComponent() {
        if (!this.get('isExpanded')) {
          this._resetCurrentHighlight();
        }
        this.set('isExpanded', true);
        this.set('queryTerm', '');
        run.schedule('afterRender', this, function () {
          this.$('input').focus();
        });
      },
      willHideDropdown: function willHideDropdown() {
        this.set('isExpanded', false);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this._handleAttrsChange();
      this.set('attachTo', '#' + this.get('elementId'));
      this.set('name', 'select-guru-' + this.get('elementId'));
    },
    didInsertElement: function didInsertElement() {
      this.$().bind('keydown', Ember['default'].run.bind(this, this._handleKeyDown));
    },
    willDestroyElement: function willDestroyElement() {
      this.$().unbind('keydown');
    },
    _resetCurrentHighlight: function _resetCurrentHighlight() {
      this.set('currentHighlight', 0);
    },
    _handleKeyDown: function _handleKeyDown(event) {
      switch (event.keyCode) {
        case 27:
          this.set('isExpanded', false);
          return;
        case 40:
          if (this.get('currentHighlight') + 1 >= this.get('_options').length) {
            return;
          }
          this.incrementProperty('currentHighlight');
          return;
        case 38:
          if (this.get('currentHighlight') === 0) {
            return;
          }
          this.decrementProperty('currentHighlight');
          return;
        case 13:
          var option = this.get('_options')[this.get('currentHighlight')];
          if (option) {
            this.send('onOptionClick', option);
          }
          this.set('isExpanded', false);
          return;
        default:
          return;
      }
    },
    _handleAttrsChange: function _handleAttrsChange() {
      var possibleOptions = [],
          availableOptions = this.get('options');

      if (this.get('multiple')) {
        possibleOptions = difference(availableOptions, this.get('value'));
      } else {
        possibleOptions = difference(availableOptions, [this.get('value')]);
      }
      this.set('_value', this.get('value'));
      this.set('_options', possibleOptions);
    },
    _searchForOptions: function _searchForOptions() {
      var term = this.get('queryTerm').toLowerCase();
      var searchKey = this.get('searchKey');

      return this.get('_options').filter(function (item) {
        return get(item, searchKey) && get(item, searchKey).toLowerCase().indexOf(term) > -1;
      });
    }
  });

});