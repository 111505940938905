define('ember-truth-helpers/helpers/or', ['exports', 'ember-truth-helpers/utils/truth-convert'], function (exports, truthConvert) {

  'use strict';

  exports.orHelper = orHelper;

  function orHelper(params) {
    for (var i = 0, len = params.length; i < len; i++) {
      if (truthConvert['default'](params[i]) === true) {
        return params[i];
      }
    }
    return params[params.length - 1];
  }

});