define('ember-aupac-typeahead/components/aupac-ember-data-typeahead', ['exports', 'ember', 'ember-aupac-typeahead/components/aupac-typeahead', 'ember-aupac-typeahead/templates/components/aupac-ember-data-typeahead/suggestion'], function (exports, Ember, AupacTypeahead, suggestionTemplate) {

  'use strict';

  var isNone = Ember['default'].isNone;
  var inject = Ember['default'].inject;
  var computed = Ember['default'].computed;
  var observer = Ember['default'].observer;

  exports['default'] = AupacTypeahead['default'].extend({

    modelClass: null, //@public
    displayKey: 'displayName', //@public
    params: {}, //@public
    async: true, //@public
    queryKey: 'q', //@public

    // @Override
    suggestionTemplate: suggestionTemplate['default'],

    //private
    store: inject.service('store'),

    /**
     * @Override
     */
    display: computed(function () {
      var _this2 = this;

      return function (model) {
        return model.get(_this2.get('displayKey'));
      };
    }),

    /**
     * @Override
     */
    setValue: function setValue(selection) {
      var _this3 = this;

      selection = this.transformSelection(selection);
      if (typeof selection === 'string') {
        this.get('_typeahead').typeahead('val', selection);
      } else {
        (function () {
          var displayKey = _this3.get('displayKey');
          var modelClass = _this3.get('modelClass');
          if (selection && selection.get('id')) {
            var item = _this3.get('store').peekRecord(modelClass, selection.get('id'));
            if (isNone(item)) {
              _this3.get('store').findRecord(modelClass, selection.get('id')).then(function (model) {
                _this3.get('_typeahead').typeahead('val', model.get(displayKey));
              });
            } else {
              _this3.get('_typeahead').typeahead('val', item.get(displayKey));
            }
          } else {
            _this3.get('_typeahead').typeahead('val', '');
          }
        })();
      }
    },

    /**
     * @Override
     */
    init: function init() {
      this._super.apply(this, arguments);

      if (isNone(this.get('modelClass'))) {
        throw new Error('modelClass must be supplied to aupac-typeahead');
      }
    },

    /**
     * @Override
     */
    source: computed(function () {
      var _this = this;
      return function (query, syncResults, asyncResults) {
        var q = {};
        q[_this.get('queryKey')] = query;
        var queryObj = $.extend(true, {}, q, _this.get('params'));

        _this.get('store').query(_this.get('modelClass'), queryObj).then(function (models) {
          var emberDataModels = [];
          models.get('content').forEach(function (model, i) {
            emberDataModels[i] = model.getRecord();
          });
          asyncResults(emberDataModels);
        });
      };
    }),

    /**
     * @Override
     */
    selectionUpdated: observer('selection.id', '_typeahead', function () {
      var selection = this.get('selection');
      if (isNone(selection)) {
        this.setValue(null);
      } else {
        this.setValue(selection);
      }
    })

  });

});